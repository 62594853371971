export const gilroyRegular = '"poppinsregular", sans-serif';
export const gilroySemibold = '"poppinssemibold", sans-serif';
export const gilroyBold = '"poppinsbold", sans-serif';
export const gilroyExtraboldItalic = '"poppinsextrabold_italic", sans-serif';
export const gilroyBlack = '"poppinsblack", sans-serif';
export const gilroyExtrabold = '"poppinsextrabold", sans-serif';
export const gilroyHeavy = '"poppinsblack", sans-serif';
export const gilroyHeavyItalic = '"poppinsblack_italic", sans-serif';
export const gilroyLight = '"poppinslight", sans-serif';
export const gilroyMedium = '"poppinsmedium", sans-serif';
export const gilroyMediumItalic = '"poppinsmedium_italic", sans-serif';
export const sourceSansPro = '"Source Sans Pro", sans-serif';
export const akiraSuperBold = '"poppinsbold", sans-serif';
export const morisonRegular = '"poppinsregular", sans-serif';
export const morisonBold = '"poppinsbold", sans-serif';
export const morisonSemiBold = '"poppinssemibold", sans-serif';
export const morisonMedium = '"poppinsmedium", sans-serif';
export const morisonLight = '"poppinslight", sans-serif';
export const morisonLightItalic = '"poppinslight_italic", sans-serif';
export const axiformaBold = '"poppinsbold", sans-serif';
export const axiformaSemiBold = '"poppinssemibold", sans-serif';
export const axiformaMedium = '"poppinsmedium", sans-serif';

import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import Loader from 'components/loader/loader';

const Home = lazy(() => import('pages/technology/wavex'));
const TechServicesPage = lazy(() => import('pages/cx/index'));
const ConnectPage = lazy(() => import('pages/connect/index'));

const ResourcesPage = lazy(() => import('pages/resources/index'));
const ResourcesLandingPage = lazy(() => import('pages/resources/landing-page'));

const EbooksIndex = lazy(() => import('pages/resources/ebooks'));
const AllEbooks = lazy(() => import('pages/resources/ebooks/all-ebooks'));

const WebinarIndex = lazy(() => import('pages/resources/webinars'));
const AllWebinars = lazy(() => import('pages/resources/webinars/all-webinars'));

const PrivacyPolicy = lazy(() => import('pages/privacy-policy'));

const WaveZero = lazy(() => import('pages/resources/blogs'));
const WaveZeroBlog = lazy(() => import('pages/resources/blogs/blog-detail'));

const CBRedirector1 = lazy(() =>
    import(
        'pages/resources/ebooks/community-bank-digital-member-experience-part-1'
    )
);

const CBRedirector2 = lazy(() =>
    import(
        'pages/resources/ebooks/community-bank-digital-member-experience-part-2'
    )
);

const CommunityBankPart1 = lazy(() =>
    import(
        'pages/resources/ebooks/community-bank-digital-member-experience-part-1'
    )
);

const CommunityBankPart2 = lazy(() =>
    import(
        'pages/resources/ebooks/community-bank-digital-member-experience-part-2'
    )
);

const ContactUs = lazy(() => import('pages/contact-us'));
const NotFoundPage = lazy(() => import('pages/not-found'));

const redirectList = [
    ['/technology/training-simulator', '/tech-services'],
    ['/technology/scorecard', '/tech-services'],
    ['/technology/inspire', '/tech-services'],
    ['/technology/virtual-floor-management-system', '/tech-services'],
    ['/technology/round', '/tech-services'],
    ['/technology/witness', '/tech-services'],
    ['/technology/heatmap', '/tech-services'],
    ['/technology/reflecx', '/tech-services'],
    ['/technology/wavex/cx-performance-acceleration', '/tech-services'],
    ['/technology/wavex/cx-interaction-management', '/tech-services'],
    ['/technology/wavex/omnichannel-cx-engagement', '/tech-services'],
    ['/digital/social-reputation-management', '/'],
    ['/join-us', '/contact-us'],

    [
        '/resources/ebooks/g/community-bank-digital-member-experience',
        '/resources/ebooks/g/community-bank-digital-customer-experience'
    ],
];

const MainRoutes = () => {
    const pathname = window.location.pathname;
    return (
        <Suspense fallback={<Loader />}>
            <Router basename="/">
                <Switch>
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/tech-services">
                        <TechServicesPage />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/cx-services">
                        <ConnectPage />
                    </Route>

                    <Route exact path="/resources">
                        <ResourcesPage />
                    </Route>
                    <Route path="/resources/:type/g/:slug">
                        <ResourcesLandingPage />
                    </Route>

                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-1">
                        <CBRedirector1 />
                    </Route>

                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-2">
                        <CBRedirector2 />
                    </Route>

                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-1">
                        <CommunityBankPart1 />
                    </Route>

                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-2">
                        <CommunityBankPart2 />
                    </Route>
                    <Route exact path="/resources/ebooks">
                        <AllEbooks />
                    </Route>
                    <Route path="/resources/ebooks/:slug">
                        <EbooksIndex />
                    </Route>

                    <Route exact path="/resources/webinars">
                        <AllWebinars />
                    </Route>

                    <Route path="/resources/webinars/:slug">
                        <WebinarIndex />
                    </Route>

                    <Route exact path="/resources/blogs">
                        <WaveZero />
                    </Route>
                    <Route path={`/resources/blogs/:topicId`}>
                        <WaveZeroBlog />
                    </Route>
                    <Route path="/contact-us">
                        <ContactUs />
                    </Route>


                    {/* Redirections*/}
                    {
                        redirectList.map((rdc, idx) => (
                            <Redirect key={idx} from={rdc[0]} to={rdc[1]} />
                        ))
                    }
                    {/*Redirections End */}

                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Router>
        </Suspense>
    );
};

export default MainRoutes;
